import React from "react";
import Navbarheader from "./Navbarheader";

const HeaderComponent = () => {
	return (
		<>
			<Navbarheader />
		</>
	);
};

export default HeaderComponent;
